<template>
	<div class="mt-5">
		<div v-if="user">
			<div class="mb-4 d-flex align-items-center justify-content-between">
				<div>
					<small class="">Benvenuto</small>
					<h3 class="mb-0">{{ user.representative }}</h3>
				</div>
				<div>
					<img :src="user.logo" class="sponsor-logo" />
				</div>
			</div>
			<div v-if="user.event">
				Indica i nominativi che potranno accedere all'area Hospitality prima dell'incontro.
				<div class="my-3 d-flex align-items-center justify-content-between">
					<strong class="text-black">{{ user.event.title }}</strong>
					<span class="">{{ convertDate(user.event.date) }}</span>
				</div>
			</div>
			<div v-show="!saved">
				<div class="form-group" :key="index" v-for="(seat, index) in seats">
					<input type="text" class="form-control my-3" placeholder="Nome e cognome" v-model="seats[index].name" />
				</div>
				<div class="mt-4">
					<button-indicator v-model="saving" @click="save">Salva</button-indicator>
				</div>
			</div>
			<div v-show="saved" class="mt-5 mb-4 alert alert-success">
				<h5 class="me-2"><i class="ion-checkmark-round" /> Fatto</h5>
				<p class="mb-0">
					La tua prenotazione è andata a buon fine.
				</p>
				<!-- <hr />
				<p class="mb-0">
					Ti ricordiamo che puoi modificare i nominativi entro 2 ore prima dell'inizio della gara.
				</p> -->
			</div>
		</div>
		<div v-else-if="user === false" class="text-center my-5">
			<router-link to="/login">Esegui prima l'accesso</router-link>
		</div>
		<div v-else>
			Carico le informazioni...
		</div>

		<div class="text-center my-5">
			<small class="text-danger" style="cursor: pointer;" @click="logout">Esci</small>
		</div>
	</div>
</template>

<script>
import { getApp } from 'firebase/app'
import { getAuth, signOut } from 'firebase/auth'
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
import { mapGetters } from 'vuex'
import ButtonIndicator from '../components/ButtonIndicator'
import moment from 'moment'

export default {
	data(){
		return {
			auth: null,
			seats: [],
			saving: false,
			saved: false,
		}
	},
	computed: {
		...mapGetters(['user', 'game'])
	},
	components: {
		ButtonIndicator,
	},
	methods: {
		async save(){
			this.saving = true

			const saveSeatsFn = httpsCallable(this.functions, 'saveSeats')
			const response = await saveSeatsFn(this.seats)

			this.saving = 1
			this.saved = true
		},
		logout(){
			signOut(getAuth())
			this.$router.push('/login')
		},
		convertDate(date){
			const dateInMillis = date._seconds * 1000
			return moment(dateInMillis).format('DD/MM/YYYY') + ' at ' + new Date(dateInMillis).toLocaleTimeString()
		}
	},
	mounted(){
		this.functions = getFunctions(getApp());
		if (process.env.VUE_APP_ENV == 'local')
			connectFunctionsEmulator(this.functions, "localhost", 5001);

		// was fixed to 2 for xmas dinner
		let sa = this.user.seats_available
		for(var i = 0; i < sa; i++)
		{
			this.seats.push({
				name: this.user.event?.participants[i]?.name || '',
			})
		}

		if (this.user === false)
			this.$router.push('/login')
	}
}
</script>

<style scoped>
	.sponsor-logo {
		height: 45px;
	}
</style>