import Home from './pages/Home'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'

export const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard
	},
]