import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions'

const actions = {
	setAuthInfo: (context, payload) =>
	{
		getAuth().onAuthStateChanged(async function(user) {
			const functions = getFunctions(getApp())
			if (process.env.VUE_APP_ENV == 'local')
				connectFunctionsEmulator(functions, "localhost", 5001)

			if (user) {
				const checkUserFn = httpsCallable(functions, 'authInfo')
				const resp = await checkUserFn()
				const user = resp.data.data
				console.log('@User is:', user)
		
				context.dispatch('setUser', {
					name: user.name,
					representative: user.representative,
					logo: user.logo,
					seats_available: user.seats_available,
					event: user.event
				})

				context.dispatch('setGame', {
					title: user.event.title,
					opponent_name: user.event.opponent_name,
					opponent_logo: user.event.opponent_logo,
					data: user.event.date,
				})
			} else {
				context.dispatch('setUser', false)
			}
		});
	}
}

export default {
	actions
}