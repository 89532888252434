<template>
	<div id="app">
		<div v-if="loading" class="mt-5 pt-5 text-center">
			<div class="spinner-wrapper">
				<div class="spinner-border spinner-border-lg" role="status">
					<!-- <span class="sr-only">Loading...</span> -->
				</div>
			</div>
		</div>
		<div v-else class="pb-5">
			<div class="topbar" />
			<div class="text-center py-2 logo-wrapper">
				<a class="d-block" href="/">
					<img class="home-logo" src="https://slyvi-tstorage.s3.amazonaws.com/tli_1841116985089_6b8feca0d5f0aec7fb97ca58bed01e8e52a70179_1622103807167954.png" />
					<img v-if="game.opponent_logo" class="visitors-logo" :src="game.opponent_logo" />
				</a>
			</div>
			<div class="container">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
	name: 'App',
	data(){
		return {

		}
	},
	computed: {
		...mapGetters(['user', 'game']),
		loading(){
			return _.isEmpty(this.user) && this.user !== false ? true : false
		}
	},
	components: {

	},
	mounted(){
		this.$store.dispatch('setAuthInfo')
	}
}
</script>

<style scoped lang="scss">
	#app {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		.logo-wrapper {
			background: #002856;
			position: relative;

			&:before {
				content: "";
				position: absolute;
				left: 0; right: 0;
				top: 0; bottom: 0;
				background: url(https://slyvi-tstorage.fra1.digitaloceanspaces.com/l17266786504_tml1841116985089_251789595861_1638093676428438.jpg) center center no-repeat;
				opacity: .05;
				filter: grayscale(100%);
			}

			img {
				height: 72px;
				position: relative;
				z-index: 5;
			}
		}
	}
	.topbar {
		background: #002249;
		height: 5px;
	}
	.home-logo {
		position: relative;
		z-index: 6 !important;
	}
	.visitors-logo {
		position: relative;
		margin-left: -25px;
	}
</style>
