const state = {
	user: {}
}

const getters = {
	user: state => state.user
}

const mutations = {
	setUser: (state, payload) => {
		state.user = payload
	}
}

const actions = {
	setUser: (context, payload) => {
		context.commit('setUser', payload)
	}
}

export default {
	state,
	getters,
	mutations,
	actions
}