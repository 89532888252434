<template>
	<component
		:id="id"
		:is="slotOnly ? 'div' : 'button'" :type="_type" class="button-indicator"
		:class="{'button-indicator': true, 'btn': !slotOnly, [variantClass]: !slotOnly, [sizeClass]: !slotOnly, 'doing': value, 'loading': value===true, 'ok': value===1, 'ko': value===-1}"
		:disabled="disabled || value===true"
		@click="$emit('click')"
		autocomplete="off"
	>
		<span><slot/></span>
		<div class="spinner-wrapper">
			<div class="spinner-border spinner-border-sm" role="status">
				<!-- <span class="sr-only">Loading...</span> -->
			</div>
		</div>
		<div class="ok"><i class="ion-checkmark-round" /></div>
		<div class="ko"><i class="ion-close-round" /></div>
	</component>
</template>

<script>
export default {
	name: 'ButtonIndicator',
	props: [
		'value',
		'id',
		'slotOnly',
		'type',
		'variant',
		'size',
		'toast',
		'successTitle',
		'successMessage',
		'errorTitle',
		'errorMessage',
		'disabled',
	],
	computed: {
		_type: function(){
			if (!this.type)
				return 'submit'
			return this.type
		},
		variantClass: function(){
			let c = ''

			if (this.variant == 'outline')
				c = 'btn-outline-primary'
			else if (this.variant == 'outline-warning')
				c = 'btn-outline-warning'
			else if (this.variant == 'outline-danger')
				c = 'btn-outline-danger'
			else
				c = 'btn-primary'
			// else if ...

			return c
		},
		sizeClass: function(){
			let c = ''

			if (this.size == 'sm')
				c = 'btn-sm'
			// else if ...

			return c
		},
		fillColor: function(){
			if (this.variant == 'outline')
				return '#1e824c'
			else
				return '#FFFFFF'
		}
	},
	watch: {
		'value': function(val, oldVal){
			// [TODO]
			// Avoid mutating a prop directly since the value will be overwritten whenever the parent component re-renders. Instead, use a data or computed property based on the prop's value. Prop being mutated: "loading"

			if (val === 1)
			{
				if (this.toast)
					this.$root.$bvToast.toast(this.successMessage || this.$t('general.done'), {
						title: `${this.successTitle || this.$t('general.done')}`,
						toaster: 'b-toaster-top-right',
						variant: 'success',
						solid: true,
						appendToast: true
					})
				setTimeout(() => {
					this.$emit('input', false)
				}, 3000)
			}
			else if (val === -1)
			{
				if (this.toast)
					this.$root.$bvToast.toast(this.errorMessage || 'Errore', {
						title: `${this.errorTitle || 'Errore'}`,
						toaster: 'b-toaster-top-right',
						variant: 'danger',
						solid: true,
						appendToast: true
					})
				setTimeout(() => {
					this.$emit('input', false)
				}, 3000)
			}
		}
	}
}
</script>

<style lang="scss">
	.button-indicator {
		position: relative;

		.unicon svg {
			fill: currentColor;
		}

		.spinner-wrapper,
		.ok,
		.ko {
			display: none;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		&.doing {
			span {
				visibility: hidden;
			}
			&.loading {
				.spinner-wrapper {
					display: block;
				}
			}
			&.ok {
				.ok {
					display: block;
				}
			}
			&.ko {
				.ko {
					display: block;
				}
			}
		}
	}
</style>