const state = {
	game: {}
}

const getters = {
	game: state => state.game
}

const mutations = {
	setGame: (state, payload) => {
		state.game = payload
	}
}

const actions = {
	setGame: (context, payload) => {
		context.commit('setGame', payload)
	}
}

export default {
	state,
	getters,
	mutations,
	actions
}