import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
import { routes } from './routes'
Vue.use(VueRouter)
const router = new VueRouter({
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes,
	mode: 'history'
})

import { initializeApp } from "firebase/app"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
	apiKey: "AIzaSyBoxkxwBl0w084LBjMxereYgNVLoh7wo0Q",
	authDomain: "ivplayground.firebaseapp.com",
	databaseURL: "https://ivplayground-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "ivplayground",
	storageBucket: "ivplayground.appspot.com",
	messagingSenderId: "783583855826",
	appId: "1:783583855826:web:6cc3ef22deb2ceace9190b"
}

// Initialize Firebase
initializeApp(firebaseConfig)

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

Vue.use(require('vue-moment'))

Vue.config.productionTip = false

import { store } from './store/store'

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
