<template>
	<div class="mt-5">
		<!-- <button @click="getAuthInfo">AUTH USER</button> -->
		<div v-if="!ask_for_code">
			<div v-if="number_not_found !== true">
				<div class="form-group">
					<label>Il tuo numero di telefono</label>
					<input type="text" class="form-control my-3" placeholder="Numero di telefono" v-model="phonenumber" @keypress="isNumber($event)" />
					<small class="text-muted mt-3">
						Inserisci il numero di telefono che hai indicato al reparto marketing per poter effettuare la prenotazione per l'area hospitality
					</small>
				</div>
				<div class="mt-4">
					<!-- <button id="sign-in-button" class="btn btn-primary text-white" @click="requestAccessCode">Richiedi codice accesso</button> -->
					<button-indicator id="sign-in-button" v-model="requesting_access_code" @click="requestAccessCode">Richiedi codice accesso</button-indicator>
				</div>
			</div>
			<div v-else>
				<p class="lead">Il numero di telefono indicato sembra non essere valido</p>
			</div>
		</div>
		<div v-else>
			<div class="form-group">
				<label>Codice di conferma</label>
				<input type="text" class="form-control my-3" placeholder="Codice" v-model="access_code" />
				<small class="text-muted mt-3">
					Inserisci il codice che hai ricevuto via SMS
				</small>
			</div>
			<div class="mt-4">
				<button-indicator v-model="signing_in" @click="signIn">Accedi</button-indicator>
				<!-- <button class="btn btn-primary text-white" @click="signIn">Accedi</button> -->
			</div>
		</div>
	</div>
</template>

<script>
import { getApp } from 'firebase/app'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import ButtonIndicator from '../components/ButtonIndicator'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
	data(){
		return {
			auth: null,
			phonenumber: (process.env.VUE_APP_ENV == 'local') ? '+393703248365' : '',
			access_code: '',
			ask_for_code: false,
			functions: {},
			number_not_found: false,
			signing_in: false,
			requesting_access_code: false,
		}
	},
	computed: {
		...mapGetters(['user']),
		cleanPhoneNumber(){
			return '+39'+(this.phonenumber.replace('+39', ''))
		},
	},
	components: {
		ButtonIndicator,
	},
	methods: {
		async requestAccessCode(){
			this.requesting_access_code = true

			const phoneNumber = this.cleanPhoneNumber
			const appVerifier = window.recaptchaVerifier

			const checkUserFn = httpsCallable(this.functions, 'checkPhone');
			const user = await checkUserFn({number: phoneNumber});
			console.log('user is ', user);

			if (user.data.status === 200)
			{
				signInWithPhoneNumber(this.auth, phoneNumber, appVerifier)
					.then((confirmationResult) => {
						console.log('YYYYY')
						// SMS sent. Prompt user to type the code from the message, then sign the
						// user in with confirmationResult.confirm(code).
						window.confirmationResult = confirmationResult;
						this.requesting_access_code = 1
						this.ask_for_code = true
						// ...
					}).catch((error) => {
						console.log('NNNNN')
						// Error; SMS not sent
						// reset the reCAPTCHA so the user can try again:
						window.recaptchaVerifier.render().then(function(widgetId) {
							grecaptcha.reset(widgetId);
						})
					})
			}
			else
			{
				this.number_not_found = true
			}
		},
		signIn(){
			this.signing_in = true

			const code = this.access_code
			window.confirmationResult.confirm(code).then((result) => {
				// User signed in successfully.
				const user = result.user;
				window.user = user
				// this.user = user
				this.signing_in = 1
				window.location.href = '/'
				// ...
			}).catch((error) => {
				// User couldn't sign in (bad verification code?)
				// ...
			});
		},
		async getAuthInfo(){
			const checkUserFn = httpsCallable(this.functions, 'authInfo');
			const user = await checkUserFn();
		},
		isNumber: function(evt) {
			evt = (evt) ? evt : window.event;
			var charCode = (evt.which) ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true
			}
		}
	},
	mounted(){
		this.functions = getFunctions(getApp());
		if (process.env.VUE_APP_ENV == 'local')
			connectFunctionsEmulator(this.functions, "localhost", 5001);

		const auth = getAuth()
		this.auth = auth
		auth.languageCode = 'it'
		window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
			'size': 'invisible',
			'callback': (response) => {
				// reCAPTCHA solved, allow requestAccessCodeWithPhoneNumber.
				onSignInSubmit();
			}
		}, auth)

		if (typeof this.user == 'object' && typeof this.user.name != 'undefined' /* !_.isEmpty(this.user)*/)
			this.$router.push('/')
	}
}
</script>

<style>

</style>