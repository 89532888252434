<template>
	<div class="mt-5">
		<div v-if="loading && !error" class="my-5 text-center">
			<div class="spinner-wrapper">
				<div class="spinner-border spinner-border-lg" role="status">
					<!-- <span class="sr-only">Loading...</span> -->
				</div>
			</div>
		</div>
		<div v-else-if="error" class="text-center">
			🤔
		</div>
		<div v-else class="">
			<b-tabs>
				<!-- Participants -->
				<b-tab title="🍔 Partecipanti" class="pt-4">
					<h4>Prossimo incontro: {{ game.title }}</h4>
					<p class="">Lista partecipanti &mdash; <span class="cursored" @click="exportToCsv">Esporta in CSV</span></p>

					<div class="mt-5">
						<div :key="sponsor_number" v-for="(sponsor, sponsor_number) in event.participants">
							<div v-if="sponsor.sponsor" class="d-flex align-items-center justify-content-between">
								<h4 class="mb-0">{{ sponsor.sponsor.name }}</h4>
								<img class="logo" :class="{'hidden': !sponsor.sponsor.logo}" :src="sponsor.sponsor.logo" />
							</div>
							<div :key="'p'+p_index" v-for="(guest, p_index) in sponsor.guests">
								{{ guest.name }}
							</div>
							<hr class="my-5" />
						</div>
					</div>
				</b-tab>
				<!-- All sponsors -->
				<b-tab title="🤑 Lista sponsor" class="pt-4">
					<div class="mt-5">
						<div :key="index" v-for="(sponsor, index) in sponsors" class="mb-4">
							<div class="d-flex align-items-center justify-content-between">
								<h4 class="mb-0">{{ sponsor.name }}</h4>
								<img class="logo" :class="{'hidden': !sponsor.logo}" :src="sponsor.logo" />
							</div>
							<div class="">
								<p class="lead mb-1">{{ sponsor.representative }} <small class="text-muted">({{ readableNumber(sponsor.number) }})</small></p>
								<p class=""><strong>{{ sponsor.seats_available }}</strong> posti</p>
							</div>
							<hr class="my-5" />
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</div>
	</div>
</template>

<script>
import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
import { exportToCsv } from '../utilities/array'
import { mapGetters } from 'vuex'

export default {
	data(){
		return {
			auth: null,
			event: false,
			sponsors: [],
			saving: false,
			functions: null,
			error: false,
		}
	},
	computed: {
		...mapGetters(['user', 'game']),
		loading(){
			return !(this.sponsors.length > 0 && this.event)
		}
	},
	methods: {
		exportToCsv(){
			let arr = [
				['SPONSOR', 'OSPITE'],
				['', ''] // just add an empty line separator
			]
			Object.keys(this.event.participants).forEach(n => {
				let sponsor_name = this.event.participants[n]?.sponsor?.name || 'no name'
				this.event.participants[n].guests.forEach(g => {
					arr.push([
						sponsor_name,
						g.name
					])
				})
				// add an empty line separator between sponsors
				arr.push([
					'', ''
				])
			})
			exportToCsv('hospitality', arr)
		},
		readableNumber(number){
			return number.replace('+39', '')
		},
		datsafail(){
			this.error = true
			this.$router.push('/')
		}
	},
	mounted(){
		this.functions = getFunctions(getApp());
		if (process.env.VUE_APP_ENV == 'local')
			connectFunctionsEmulator(this.functions, "localhost", 5001);

		const participantsFn = httpsCallable(this.functions, 'getNextGameParticipants')
		participantsFn().then(res => {
			this.event = res.data.data.event
			if (res.data.status == 401)
				this.datsafail()
		}).catch(err => {
			this.datsafail()
		})

		const sponsorsFn = httpsCallable(this.functions, 'getAllSponsors')
		sponsorsFn().then(res => {
			this.sponsors = res.data.data
			if (res.data.status == 401)
				this.datsafail()
		}).catch(err => {
			this.datsafail()
		})
	}
}
</script>

<style scoped>
	.cursored {
		cursor: pointer;
	}
	.logo {
		width: 80px;
		height: 40px;
		object-fit: contain;
	}
	.hidden {
		visibility: hidden;
	}
	hr {
		background-color: #dee2e6 !important;
		opacity: .8 !important;
	}
</style>